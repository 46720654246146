import * as React from "react";
import './NestedForm.css';
import Checkbox from "./Checkbox";

function NestedForm({children, onChange, name: formName, value}) {

    const childOnChange = (e, name, inputValue) => {
        let newValue = inputValue || e?.target?.value || '';
        let inputName = name || e.target.name;
        if (e?.target?.type === "checkbox") {
            newValue = e.target.checked;
        }
        const nestedData = value;
        nestedData[inputName] = newValue;
        onChange(null, formName, nestedData);
    }

    const onChangeNestedForm = (nestedFormName) => (e, nestedInputName, val) => {
        const newValue = value;
        newValue[nestedFormName] = val;
        onChange(null, formName, newValue);
    }

    return React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const childProps = {
                onChange: childOnChange
            };

            if (child.type === NestedForm) {
                childProps.onChange = onChangeNestedForm(child.props.name);
                childProps.value = value[child.props.name] ? value[child.props.name] : '';
                childProps.label = child.props.label;
            } else if (child.type === Checkbox) { // check if input type is checkbox
                childProps.value = value?.[child.props.name] ? value[child.props.name] : false;
            } else {
                childProps.value = value?.[child.props.name] ? value[child.props.name] : '';
            }
            return React.cloneElement(child, childProps);
        }
        return child;
    })
}

export default NestedForm;