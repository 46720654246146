import React from 'react';
import Loading from "./Loading";
import Error from "./Error";
import {Link} from "react-router-dom";

import './OverviewTable.css';

function OverviewTable(props) {
    const columns = props.columns;

    const _renderTableRows = (rows) => {
        return rows?.map((row, i) => {
            let rows = _renderRows(i, row);
            return (
                <tr>
                    {React.Children.toArray(rows)}
                </tr>
            );
        });
    };

    const _getActions = (row) => {
        const actions = [];
        if (props?.actions?.length) {
            props.actions.forEach((action, i) => {
                const component = action.component(row);
                actions.push(
                    <td className="action">
                        <div>
                            {component}
                        </div>
                    </td>
                );
            });
            return React.Children.toArray(actions);
        }
        return [];
    }

    const buildLink = (renderer, data) => {
        const linkData = renderer(data);
        if (linkData) {
            return <Link to={linkData.route}>{linkData.label}</Link>;
        }
        return null;
    }

    const _renderRows = (i, row) => {
        const result = [];
        // render actions
        columns.forEach(col => {
            let value = row[col.key] || '';

            if (col.linkRenderer) {
                value = buildLink(col.linkRenderer, row)
            } else if (col.valueRenderer) {
                value = col.valueRenderer(row);
            }

            // check if columns is expandable
            // if (col.expandable) {
            //     result.push(
            //         <td key={`my_table_td_${i}-${col.key}`}>
            //             <div>
            //                 <FontAwesomeIcon size={"1x"} icon='chevron-down'/>
            //                 {value}
            //             </div>
            //         </td>
            //     );
            // } else {
            result.push(<td>{value}</td>);
            // }
        });
        let actions = _getActions(row);
        result.push(...actions);
        return React.Children.toArray(result);
    };

    const _renderColumnNames = (columns) => {
        const result = [];
        columns.forEach((row, i) => {
            result.push(<th key={`my_table_th_${i}`}>{row.title}</th>);
        });
        props.rows?.length && props.actions?.forEach((_, i) => result.push(<th key={`my_table_th_action_${i}`}/>));
        return result;
    };

    const _getTableActions = () => {
        return props.tableActions ? React.Children.toArray(props.tableActions.map(i => i.component)) : null;
    };

    const renderTable = () => {

        return (
            <div>
                <div className="header-row">
                    {props.title && <h3>{props.title}</h3>}
                    {_getTableActions()}
                </div>
                <table>
                    <thead>
                    <tr>{React.Children.toArray(_renderColumnNames(columns))}</tr>
                    </thead>
                    <tbody>
                    {React.Children.toArray(_renderTableRows(props.rows))}
                    </tbody>
                </table>
            </div>
        );
    }


    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderTable();
        }
    };

    const filterOnChange = (ref, name, value) => {
        console.log(ref);
    }

    const getFilters = () => {
        if (props.filters) {
            return React.Children.map(props.filters, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        value: '',
                        onChange: filterOnChange
                    });
                }
                return child;
            });
        }
    }

    return (
        <div className="OverviewTable">
            {props.filters}
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default OverviewTable;