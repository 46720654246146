import React from 'react';
import t from "../hooks/useTranslation";
import './Error.css';

export default function Error({error}) {
    let message = '';
    if (error.response?.data?.errorCode) {
        const errMsg = error.response?.data?.err?.message || error.response.data.errorCode;
        message = `Chyba při zpracování požadavku (${error.response.status}): ${t(errMsg)}`;
    } else if (error?.response?.data) {
        let {data} = error.response;
        message = `${error.message}: ${data.errorMessage}`;
    } else {
        message = error.message;
    }

    return (
        <div className="Error alert alert-danger" role="alert">
            {message}
        </div>
    );
}
