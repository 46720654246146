import './Checkbox.css';
import * as React from "react";

function Checkboxes({options, label, value = '', ...props}) {

    const handleChange = (e) => {
        props?.onChange(e.target.value, e.target.checked);
    }

    const renderContent = (option, checked) => {
        if (props.hasOwnProperty('contentRenderer')) {
            return props.contentRenderer(option, checked, handleChange);
        } else {
            return (
                <>
                    <input checked={checked} onChange={handleChange}
                           type="checkbox" name={option.value} value={option.value}/>
                    {option.label}
                </>
            )
        }
    }

    return (
        <label className="FormInput FormCheckboxes">
            <label className={props.required ? 'required-label' : ''}>{label}</label>
            <div className="choices">
                {options.map(option => {
                    return (<div key={JSON.stringify(option.value)}>
                        <label className="choice-label">
                            {renderContent(option, value.indexOf(option.value) !== -1, props.onChange)}
                        </label>
                    </div>);
                })}
            </div>
        </label>
    );
}

export default Checkboxes;