import * as React from "react";

import Overview from "../components/Overview";
import t from "../hooks/useTranslation";
import UserForm from "../components/UserForm";

import './UserAdd.css';

function UserAdd(props) {

    return (
        <div className="UserAdd">
            <Overview
                introduction={t('introductionText')}
                title={t('newUser')}
            >
                <UserForm
                    history={props.history}
                />
            </Overview>
        </div>
    );
}

export default UserAdd;
