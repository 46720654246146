import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import QRCode from "react-qr-code";
import {objectId, onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";

import './VehicleForm.css';

const authService = new AuthService();

const vehicleTypes = [{label: 'osobní', value: 'personal'}, {label: 'užitkové', value: 'commercial'}];
const vehicleSubTypes = [{label: 'BB skříňový', value: 'BBbox'}, {label: 'box', value: 'skříňový'}];
const cngOptions = [{label: 'není', value: false}, {label: 'je', value: true}];
const fuelOptions = [
    {label: t('diesel'), value: 'diesel'},
    {label: t('gas'), value: 'gas'},
    {label: t('lpg'), value: 'lpg'},
    {label: t('cng'), value: 'cng'}
];

const INITIAL_FORM_DATA = {
    id: objectId(),
    owner: '',
    licenceNumber: '',
    ccsNumber: '',
    ccsPin: '',
    series: '',
    capacity: '',
    engineCapacity: '',
    vin: '',
    type: 'utility',
    subType: 'BBbox',
    cng: false,
    ac: true,
    fuel: 'diesel',
    cngNopek: '',
    stkExpirationDate: ''
}

function VehicleForm(props) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (formData._id) {
                await authService.put(config.apiRouteMap.getVehicle(formData._id), formData);
            } else {
                await authService.post(config.apiRouteMap.vehicle, formData);
            }
            setError(null);
            props.history.push(config.routeMap.vehicles);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const onDelete = async () => {
        if (window.confirm(t('uSureToDeleteItem', {label: formData.licenceNumber}))) {
            try {
                setIsLoading(true);
                if (formData._id) {
                    await authService.delete(config.apiRouteMap.getVehicle(formData._id));
                }
                setError(null);
                props.history.push(config.routeMap.vehicles);
            } catch (err) {
                setIsLoading(false);
                setError(err);
            }
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <Forms.Text label={t('vehicleOperator')} value={formData.owner} name='owner'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('vehicleModel')} value={formData.model} name='model' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('licenceNumber')} value={formData.licenceNumber} name='licenceNumber'
                                    disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('ccsNumber')} value={formData.ccsNumber} name='ccsNumber'
                                    disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('ccsPin')} value={formData.ccsPin} name='ccsPin' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('vin')} value={formData.vin} name='vin' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('series')} value={formData.series} name='series' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Number label={t('capacity')} value={formData.capacity} name='capacity' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Number label={t('engineCapacity')} value={formData.engineCapacity} name=' engineCapacity'
                                      disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('vehicleType')} value={formData.type} name='type' disabled={isLoading}
                                      options={vehicleTypes} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('vehicleSubType')} value={formData.subType} name='subType'
                                      disabled={isLoading}
                                      options={vehicleSubTypes} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('cng')} value={formData.cng} name='cng' disabled={isLoading}
                                      options={cngOptions} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('ac')} value={formData.ac} name='ac' disabled={isLoading}
                                      options={cngOptions} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('engine')} value={formData.fuel} name='fuel' disabled={isLoading}
                                      options={fuelOptions} onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('cngNopek')} value={formData.cngNopek} name='cngNopek' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Date label={t('stkExpirationDate')} value={formData.stkExpirationDate}
                                    name='stkExpirationDate'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                    </form>
                    {_renderQrCode()}
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <div className="rightSection">
                        {formData._id &&
                            <Forms.Button dark label={t('delete')} onClick={onDelete} disabled={isLoading}/>}
                        <Forms.Button label={_getButtonLabel()} onClick={onSubmit} disabled={isLoading}/>
                    </div>
                </div>
            </>
        );
    }

    const _renderQrCode = () => {
        if (formData.id) {
            return (
                <div className="qrCode blur">
                    <QRCode value={formData.id} size={180}/>
                    <label>{t('vehicleQrAfterCreation')}</label>
                </div>
            );
        } else {
            return (
                <div className="qrCode">
                    <QRCode value={formData._id} size={180}/>
                    <label>{t('vehicleQr')}</label>
                </div>
            );
        }
    }

    const _getButtonLabel = () => {
        return formData._id ? t('save') : t('create');
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="VehicleForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default VehicleForm;