import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";

import './RouteForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    number: '',
    type: 'daily',
    operatingDays: [],
    stopList: [],
    driver: '',
    vehicle: ''
}

function RouteForm(props) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [allStops, setAllStops] = useState();
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        const init = async () => {
            // Load drivers, stops, and vehicles
            setDrivers((await authService.get(config.apiRouteMap.listDrivers)).data);
            setAllStops((await authService.get(config.apiRouteMap.client)).data);
            setVehicles((await authService.get(config.apiRouteMap.vehicle)).data);
        };
        init();
    }, []);

    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (formData._id) {
                await authService.put(config.apiRouteMap.getRoute(formData._id), formData);
            } else {
                await authService.post(config.apiRouteMap.route, formData);
            }
            setError(null);
            props.history.push(config.routeMap.routes);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const stopListOnChange = newSelectedStop => {
        setFormData(prevData => ({
                ...prevData,
                stopList: newSelectedStop.map((stop, i) => ({client: stop._id, note: stop.note, order: i}))
            }
        ));
    }

    const onDelete = async () => {
        if (window.confirm(t('uSureToDeleteItem', {label: formData.name}))) {
            try {
                setIsLoading(true);
                if (formData._id) {
                    await authService.delete(config.apiRouteMap.getRoute(formData._id));
                }
                setError(null);
                props.history.push(config.routeMap.routes);
            } catch (err) {
                setIsLoading(false);
                setError(err);
            }
        }
    }

    const handleCheckboxChange = (name, checked) => {
        const dayIndex = parseInt(name);  // Use the checkbox's name as the day index
        let newDays = [...formData.operatingDays];

        if (checked) {
            newDays = [...newDays, dayIndex].sort();
        } else {
            newDays = newDays.filter(day => day !== dayIndex);
        }

        setFormData(prevData => ({
            ...prevData,
            operatingDays: newDays
        }));
    };

    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const renderDayIndexes = [1, 2, 3, 4, 5, 6, 0];
    const renderForm = () => {

        const vehicleOptions = [{value: null, label: ''}, ...vehicles.map(vehicle => ({
            label: vehicle.licenceNumber,
            value: vehicle._id
        }))];
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <Forms.Text label={t('routeName')} value={formData.name} name='name'
                                    disabled={isLoading} onChange={onChange(setFormData)} required/>
                        <Forms.Text label={t('routeNumber')} value={formData.number} name='number'
                                    disabled={isLoading} required
                                    onChange={onChange(setFormData)}/>
                        <Forms.Select label={(t('driver'))} value={formData.driver} name='driver' disabled={isLoading}
                                      onChange={onChange(setFormData)}
                                      options={[{value: null, label: ''}, ...drivers.map(driver => ({
                                          label: `${driver.name} ${driver.surname}`,
                                          value: driver._id
                                      }))]} required/>
                        <Forms.Select label={(t('vehicle'))} value={formData.vehicle} name='vehicle'
                                      disabled={isLoading} required
                                      onChange={onChange(setFormData)} options={vehicleOptions}/>
                        <div className="daysContainer item-span-2">
                            <label className="headerLabel">Jezdit ve dnech:</label>
                            <div className="checkbox-container">
                                {renderDayIndexes.map((dayIndex) => {
                                        return <Forms.Checkbox label={t(dayNames[dayIndex])} name={dayIndex.toString()}
                                                               checked={formData.operatingDays.includes(dayIndex)}
                                                               key={dayIndex}
                                                               disabled={isLoading} onChange={handleCheckboxChange}/>
                                    }
                                )}
                            </div>
                        </div>
                        {
                            allStops && <Forms.RouteStopSelector onChange={stopListOnChange}
                                                                 className='item-span-2'
                                                                 selectedStopList={formData.stopList}
                                                                 stopList={allStops}/>
                        }
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <div className="rightSection">
                        {formData._id &&
                            <Forms.Button dark label={t('delete')} onClick={onDelete} disabled={isLoading}/>}
                        <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                    </div>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="RouteForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default RouteForm;