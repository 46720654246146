import {useState} from "react";
import * as React from "react";
import Logo from '../img/logo-1.png';
import Forms from '../form/Forms';
import AuthService from "../AuthService";
import Error from "../components/Error";
import t from "../hooks/useTranslation";
import {useAuth} from "../context/AuthContext";
import {useHistory} from "react-router-dom";
import config from "../config/config";

import './Login.css';

const authService = new AuthService();

function LoginContent() {
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const history = useHistory();

    const {login} = useAuth();

    const submit = async () => {
        try {
            const {accessToken, refreshToken} = await authService.login({email, password, remember});
            setError(null);
            login(accessToken, refreshToken);
            history.push(config.routeMap.dashboard);
        } catch (err) {
            setError(err);
        }
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            submit();
        }
    }

    return (
        <div className="left-container">

            <img className="logo" src={Logo} alt="logo"/>

            <h2>{t('logInToApplication')}</h2>

            <div className="input-container">
                <label>
                    {t('loginName')}
                    <input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
                </label>
            </div>

            <div className="input-container">
                <label>
                    {t('password')}
                    <input type="password" name="password" onKeyPress={handleKeyPress}
                           onChange={e => setPassword(e.target.value)}
                           value={password}/>
                </label>
            </div>

            <div className="login-row">
                <div className="remember">
                    <Forms.Checkbox checked={remember} name="remember" label="Zůstat přihlášen"
                                    onChange={e => setRemember(e.target.checked)}/>
                </div>

                <label className="forgotten-password" onClick={() => history.push(config.routeMap.forgottenPassword)}>{t('forgottenPassword')}</label>
            </div>

            <Forms.LoginButton label={t('logIn')} onClick={submit}/>
            {error && <Error error={error}/>}


        </div>
    );
}

export default LoginContent;
