import * as React from "react";
import Overview from "../components/Overview";
import useOverviewData from "../hooks/useOverviewData";
import config from "../config/config";
import OverviewTable from "../components/OverviewTable";
import {Link} from "react-router-dom";
import moment from "moment";
import t from "../hooks/useTranslation";

import './Users.css';

const INITIAL_BRICKS_DATA = [
    {
        label: 'Celkem uživatelů',
        isLoading: true,
        dataKey: 'userCount'
    },
    {
        label: '...',
        isLoading: true,
        dataKey: ''
    },
    {
        label: '...',
        isLoading: true,
        dataKey: ''
    },
    {
        label: '...',
        isLoading: true,
        dataKey: ''
    }
];

function Users() {
    const {isLoading, error, response, bricksData} = useOverviewData('/api/user/dashboard', INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'name',
            title: t('name'),
            valueRenderer: ({name, surname, _id}) => <Link to={config.routeMap.getUserDetail(_id)}>
                {`${name} ${surname}`}
            </Link>
        },
        {
            key: 'jobPosition',
            title: t('jobPosition'),
            valueRenderer: ({profileList}) => profileList.map(role => t(`role${role}`)).join(', ')
        },
        {
            key: 'createdAt',
            title: t('registered'),
            valueRenderer: ({createdAt}) => createdAt && `${moment(createdAt).format('DD.MM.YYYY')}`
        },
        {
            key: 'hiredDate',
            title: t('hiredDate'),
            valueRenderer: ({hiredDate}) => hiredDate && `${moment(hiredDate).format('DD.MM.YYYY')}`
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="action1" to={config.routeMap.getUserDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        {
            component: <Link to={config.routeMap.addUser}>{t('newUser')}</Link>
        }
    ];

    return (
        <div className="Users">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('driversAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('userList')} columns={columns}
                               rows={response?.overviewData}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Users;
