import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";

import Login from "./routes/Login";
import Dashboard from "./routes/Dashboard";
import Navbar from "./routes/Navbar";

import './App.css';

import '@fortawesome/fontawesome-svg-core/styles.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import Vehicles from "./routes/Vehicles";
import VehiclesMaintenance from "./routes/VehiclesMaintenance";
import Drivers from "./routes/Drivers";
import Clients from "./routes/Clients";
import Routes from "./routes/Routes";
import LoggedUser from "./components/LoggedUser";
import config from "./config/config";
import VehicleDetail from "./routes/VehicleDetail";
import VehicleAdd from "./routes/VehicleAdd";
import VehicleMaintenanceAdd from "./routes/VehicleMaintenanceAdd";
import DriverAdd from "./routes/DriverAdd";
import DriverDetail from "./routes/DriverDetail";
import VehicleMaintenanceDetail from "./routes/VehicleMaintenanceDetail";
import ClientAdd from "./routes/ClientAdd";
import ClientDetail from "./routes/ClientDetail";
import {useAuth} from "./context/AuthContext";
import {AxiosWrapper} from "./axios";
import RouteAdd from "./routes/RouteAdd";
import RouteDetail from "./routes/RouteDetail";
import Users from "./routes/Users";
import UserAdd from "./routes/UserAdd";
import UserDetail from "./routes/DriverDetail";

library.add(fas, fab);

function App() {
    const {isLoggedIn} = useAuth();

    return (
        <div className="App">
            <AxiosWrapper/>
            <Router>
                <Switch>
                    {isLoggedIn ? (
                        <>
                            <Route exact path="/">
                                <Redirect to={config.routeMap.dashboard}/>
                            </Route>
                            <Navbar/>
                            <LoggedUser/>
                            <div className="content">

                                {/* overview routes */}
                                <Route path={config.routeMap.dashboard} component={Dashboard}/>
                                <Route path={config.routeMap.vehicles} component={Vehicles}/>
                                <Route path={config.routeMap.vehiclesMaintenance} component={VehiclesMaintenance}/>
                                <Route path={config.routeMap.drivers} component={Drivers}/>
                                <Route path={config.routeMap.users} component={Users}/>
                                <Route path={config.routeMap.clients} component={Clients}/>
                                <Route path={config.routeMap.routes} component={Routes}/>

                                {/* add routes */}
                                <Route path={config.routeMap.addVehicleMaintenance} component={VehicleMaintenanceAdd}/>
                                <Route path={config.routeMap.addVehicle} component={VehicleAdd}/>
                                <Route path={config.routeMap.addDriver} component={DriverAdd}/>
                                <Route path={config.routeMap.addUser} component={UserAdd}/>
                                <Route path={config.routeMap.addRoute} component={RouteAdd}/>
                                <Route path={config.routeMap.addClient} component={ClientAdd}/>

                                {/* edit routes */}
                                <Route path={config.routeMap.editVehicle} component={VehicleDetail}/>
                                <Route path={config.routeMap.editRoute} component={RouteDetail}/>
                                <Route path={config.routeMap.editVehicleMaintenance}
                                       component={VehicleMaintenanceDetail}/>
                                <Route path={config.routeMap.editDriver} component={DriverDetail}/>
                                <Route path={config.routeMap.editUser} component={UserDetail}/>
                                <Route path={config.routeMap.editClient} component={ClientDetail}/>

                            </div>
                        </>
                    ) : (
                        <Route path="/:action?" component={Login}/>
                    )}
                </Switch>
            </Router>
        </div>
    );
}

export default App;
