import translations from '../translations.json';

export default function t(key, variables) {
    let translation = translations[key]?.['cs'] || key;

    // replace variables
    if (variables) {
        console.log(variables);
        Object.entries(variables).forEach(([variable, value]) => {
            translation = translation.replace(new RegExp(`%${variable}%`, 'g'), value);
        })
    }

    return translation;
}
